@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
  font-family: "BigNoodle";
  src: url('./fonts/big_noodle_titling.ttf') format('truetype'); 
}

@font-face {
  font-family: "MessinaRegular";
  src: url('./fonts/MessinaSans-Regular.ttf') format('truetype'); 
}

.font-bignoodle {
  font-family: BigNoodle
}

.font-messina {
  font-family: MessinaRegular;
}

