.img-hover-zoom {
  transition: transform 0.3s ease-in-out;
}

.img-hover-zoom:hover {
  transform: scale(1.1);
}

.back-ground-bo{
  background-image: linear-gradient(60deg, #fad530 0%, #dc3d41 100%);
}
