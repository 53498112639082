.contact-container {
    padding: 2rem;
    padding-top: 5rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .contact-header h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .contact-header p {
    font-size: 1rem;
    color: #555;
  }
  
  .contact-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .contact-form {
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .contact-form h2 {
    margin-bottom: 1rem;
  }
  
  .contact-form .form-group {
    margin-bottom: 1rem;
  }
  
  .contact-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .contact-form .form-group input,
  .contact-form .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .contact-form .submit-button:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .contact-info h2 {
    margin-bottom: 1rem;
  }
  
  .contact-map {
    margin-top: 2rem;
  }
  
  .contact-map h2 {
    margin-bottom: 1rem;
  }
  

  