.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: yellow;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #000000;
  background-color: yellow;
}

/* navbar.css */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Asegúrate de que sea un valor alto para estar encima del contenido */
}
