.background-stuff {
  position: absolute;
  top: 20;
  left: 0;
  width: 100%;
  height: 30%;
  overflow: hidden;
  background: #98c5e8;
  animation: wrapperIn 2s;
}

.background-stuff .bg {
  position: absolute;
  top: 0%;
  width: 50%;
  height: 50%;
  background: #636ed1;
  animation: bgIn 4s 0s linear infinite;
  transform: skewX(10deg);
  z-index: 1;
}

.background-stuff .bg-2 {
  background: #8faaff;
  animation: bgIn 4s 1s linear infinite;
}

.background-stuff .bg-3 {
  background: #5a73da;
  animation: bgIn 4s 2s linear infinite;
}

.background-stuff .ground {
  position: absolute;
  bottom: 0;
  margin-bottom: -80px;
  height: 50%;
  width: 100%;
  background: #ffc253;
  z-index: 2;
}

.container {
  height: 100%;
  width: 100%;
  display: table;
  overflow: hidden;
}

.container .car-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.car {
  width: 320px;
  height: 200px;
  position: relative;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  z-index: 4;
  animation: carMove 0.3s infinite;
  margin-top: 4rem;
}

.car .body {
  position: absolute;
  top: 0;
  left: 14px;
  width: 230px;
  height: 160px;
  background: #fff;
}

.car .body:before {
  content: "";
  position: absolute;
  left: -14px;
  border-radius: 10px 10px 0 0;
  width: 254px;
  z-index: 2;
  height: 12px;
  background: #3190dd;
}

.car .body div {
  position: absolute;
  background: #fff;
  width: 290px;
  height: 60px;
  bottom: -10px;
  border-radius: 0 0 10px 10px;
}

.car .body div:before {
  content: "";
  background: #fff;
  position: absolute;
  top: -29px;
  right: 0;
  width: 80px;
  height: 40px;
  z-index: 1;
  border-radius: 0 14px 0 0;
  transform: rotate(17deg);
}

.car .body div:after {
  content: "";
  background: #fff;
  position: absolute;
  top: -82px;
  right: 17px;
  width: 90px;
  height: 40px;
  z-index: 1;
  border-radius: 10px 0 0 0;
  transform: rotate(75deg);
}

.car .wheel {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #3c464c;
  border-radius: 50%;
  bottom: 0;
  left: 48px;
  z-index: 14;
  animation: carMove 0.3s 0.2s;
}

.car .wheel:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.car .wheel:nth-of-type(2) {
  left: 210px;
}

.car .decos {
  width: 290px;
  height: 168px;
  position: absolute;
  z-index: 12;
  top: 0;
  left: 14px;
}

.car .line-bot {
  position: absolute;
  bottom: 9px;
  height: 3px;
  width: 100%;
  background: #e1e8ef;
}

.car .door {
  position: absolute;
  left: 120px;
  bottom: 0;
  width: 60px;
  height: 141px;
  border-radius: 10px 10px 0 0;
  border: 3px solid #e1e8ef;
  border-bottom: 0;
  background: #fff;
}

.car .door:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 7px;
  width: 46px;
  height: 40px;
  border-radius: 4px;
  background: #5bc2ef;
}

.car .door .handle {
  position: absolute;
  right: 10px;
  top: 70px;
  width: 10px;
  height: 10px;
  background: #e1e8ef;
  border-radius: 50%;
}

.car .door .handle:after {
  content: "";
  width: 20px;
  height: 4px;
  border-radius: 10px;
  background: #e1e8ef;
  position: absolute;
  top: 50%;
  left: -5px;
  margin-top: -2px;
}

.car .door .bottom {
  position: absolute;
  bottom: 6px;
  left: 11px;
  width: 38px;
  height: 12px;
}

.car .door .bottom:before, 
.car .door .bottom:after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background: #e1e8ef;
  height: 4px;
  border-radius: 10px;
  margin-bottom: 4px;
}

.car .window {
  position: absolute;
  top: 18px;
  left: 200px;
  width: 32px;
  background: #5bc2ef;
  height: 72px;
  border-radius: 10px 10px 0 10px;
}

.car .window:before {
  content: "";
  width: 100%;
  height: 20px;
  background: #5bc2ef;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 47px;
  border-radius: 0 10px 10px 10px;
}

.car .window:after {
  content: "";
  height: 20px;
  background: #5bc2ef;
  position: absolute;
  top: 17px;
  left: -13px;
  width: 67px;
  transform-origin: bottom;
  transform: rotate(74deg);
  border-radius: 10px 10px 10px 10px;
}

.car .light {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ff8c40;
  right: 60px;
  bottom: 50px;
  border-radius: 3px;
}

.car .light-front {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ff8c40;
  right: -3px;
  bottom: 57px;
  border-radius: 3px;
}

.car .light-front:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #ff8c40;
  top: 100%;
  height: 15px;
  border-radius: 3px;
  margin-top: 2px;
}

.car .antenna {
  position: absolute;
  width: 26px;
  height: 10px;
  background: #fff;
  bottom: 100%;
  left: 17px;
  border-radius: 4px 4px 0 0;
}

.car .antenna:before {
  content: "";
  position: absolute;
  width: 38px;
  height: 4px;
  background: #fff;
  bottom: 0;
  left: -22px;
  transform: rotate(55deg);
  transform-origin: right;
  border-radius: 4px;
}

.car .ice-cream {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border: 3px solid rgba(246, 200, 102, 0.31);
  border-radius: 50%;
  transform: rotate(-30deg);
}

.car .ice-cream .cone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  border-right: 18px solid transparent;
  border-left: 18px solid transparent;
  border-top: 40px solid #f4b224;
}

.car .ice-cream .cone:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translate(-50%);
  width: 30px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #fff, 0 0 0 10px rgba(0,0,0,0.1);
}

@keyframes carMove {
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

@keyframes wind {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes carDrive {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

@keyframes bgIn {
  0% { left: -100%; }
  100% { left: 100%; }
}

@keyframes wrapperIn {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}
