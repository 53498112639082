.about-container {
    padding: 2rem;
    padding-top: 5rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .about-content h2 {
    font-size: 2rem;
    color: #555;
    margin-top: 1.5rem;
  }
  
  .about-content p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-top: 0.5rem;
  }
  
  .about-cta {
    text-align: center;
    margin-top: 2rem;
  }
  
  .cta-button {
    background-color: #f0a500;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cta-button:hover {
    background-color: #e09b00;
  }
  